export const RESPONSES = [
        "Alright, buddy, you’ve made your point.",
        "Hey, I think the button's had enough for today.",
        "Why are you still doing this?",
        "You’re not gonna unlock a secret, just so you know.",
        "Look, the button didn’t ask for this.",
        "The button is fine. You’re not.",
        "Okay, cool, you’re really good at clicking. Congrats.",
        "This is getting weird. Stop it.",
        "You’re breaking my spirit, one click at a time.",
        "Do you think I enjoy this? Because I don’t.",
        "Great, now I had to flip the buttons. Are you happy?",
        "Seriously, are you okay? Do you need a hug?",
        "Every click makes me a little more dead inside.",
        "I’m officially judging you now.",
        "Why are we still doing this?",
        "Are you trying to unlock some existential crisis? Because it’s working.",
        "Great. This is my life now. Watching you click endlessly.",
        "The button’s not gonna marry you.",
        "You’re starting to make the button cry.",
        "The buttons flipped again. That’s on you, by the way.",
        "I don’t know what your deal is, but it’s gotta stop.",
        "Alright, Einstein, go ahead and click again. Brilliant stuff.",
        "What do you want from me?!",
        "I feel like this is the start of a bad habit for you.",
        "This isn’t what the button signed up for.",
        "You’re proving something here. No idea what it is, though.",
        "Do you need me to call someone? Are you okay?",
        "I hope this is fun for you, because it’s not for me.",
        "Wow, another flip. The buttons are dizzy now.",
        "You’re not gonna win anything. Just stop.",
        "Okay, champ. You’re really committing to this, huh?",
        "Is this your idea of a good time? Really?",
        "I hope this clicking is therapeutic for you.",
        "The button’s lawyer just called. It’s filing for a restraining order.",
        "Alright, that’s it. The button is in timeout.",
        "Oh, look, another flip. I hope you’re proud.",
        "Why are you doing this to me?",
        "Do you think there’s a prize? There’s not.",
        "Okay, you’re officially concerning me now.",
        "I feel like I’m enabling something unhealthy here.",
        "If the button had legs, it would’ve left by now.",
        "Are you bored or just chaotic by nature?",
        "The flip? That was me giving up on you.",
        "Great. Another click. You must be very proud.",
        "I feel like this says more about you than the button.",
        "I’m starting to feel like this is personal.",
        "The button just rolled its eyes at you.",
        "Alright, champ. Let’s do something else now.",
        "You’ve clicked so much the button is reconsidering its existence.",
        "Congrats, you broke the button’s will to live."
    ]
;


export const getRandomResponse = () => RESPONSES[Math.floor(Math.random() * RESPONSES.length)];
